import { drawCard } from './canvasMethods'
import { Card } from '../Models/Card'
import { cardState, getDraggedCards, appState, getCurrentCardState } from './state'

export function drawFoundation(ctx: CanvasRenderingContext2D) {
    const currentState = getCurrentCardState()
    for (const i in currentState.foundation) {
        const foundation = currentState.foundation[i]
        for (const j in foundation) {
            const card = foundation[j]
            drawCard(card, ctx)
        }
    }
}

export function drawWaste(ctx: CanvasRenderingContext2D) {
    const currentState = getCurrentCardState()
    const wasteCards: Card[] = currentState.waste.slice(
        currentState.waste.length - appState.NUM_CARDS_FLIPPED,
        currentState.waste.length
    )
    for (const i in wasteCards) {
        const card = wasteCards[i]
        drawCard(card, ctx)
    }
}

export function drawStock(ctx: CanvasRenderingContext2D) {
    const currentState = getCurrentCardState()
    for (const i in currentState.stock) {
        const card: Card = currentState.stock[i]
        drawCard(card, ctx)
    }
}

export function drawTableau(ctx: CanvasRenderingContext2D) {
    const currentState = getCurrentCardState()
    for (const i in currentState.tableau) {
        const indexI = parseInt(i)
        const tableau = currentState.tableau[i]
        for (const j in tableau) {
            const card = tableau[j]

            drawCard(card, ctx)
        }
    }
}

export function drawDraggedCards(ctx: CanvasRenderingContext2D) {
    // TODO: for dragged cards
    const draggedCards = getDraggedCards()
    for (const i in draggedCards) {
        const card = draggedCards[i]
        drawCard(card, ctx)
    }
}
