import { initiateAudio } from './audioFunctions'
import {
    canvasTouchEnd,
    canvasTouchMove,
    canvasTouchStart,
    canvasMouseDown,
    canvasMouseMove,
    canvasMouseUp,
    canvasMouseDblClick,
} from './canvasMouseMethods'
import { paint } from './paint'
import { addImagesToDeck, deal, setButtonImages, shuffle } from './setupCards'
import {
    cardState,
    getDraggedCards,
    setCardHeight,
    setCardSpacing,
    setCardWidth,
    setCardYOffset,
    setFoundationXOffset,
    setWindowHeight,
    setWindowWidth,
    undo,
    appState,
} from './state'

window.addEventListener('load', async () => await pageSetup())
window.onload = () => {
    //@ts-ignore
    window.draw = paint
    //@ts-ignore
    window.cardsState = cardState
    //@ts-ignore
    window.cardState = cardState
    //@ts-ignore
    window.appState = appState
    //@ts-ignore
    window.getDraggedCards = getDraggedCards
    //@ts-ignore
    window.undo = () => {
        undo()
        paint()
    }
}
window.addEventListener('resize', () => requestAnimationFrame(() => paint()))

window.addEventListener('mousemove', e => {
    // document.getElementById('mouseCoordinates').innerText = `X: ${e.x}
    // Y: ${e.y}`
})

window.addEventListener('keydown', e => {
    if (e.code === 'KeyS') {
        console.log({ cardsState: cardState })
    }
})

window.addEventListener(
    'touchstart',
    e => {
        canvasTouchStart(e)
    },
    { passive: false }
)
window.addEventListener(
    'touchend',
    e => {
        canvasTouchEnd(e)
    },
    { passive: false }
)
window.addEventListener(
    'touchmove',
    e => {
        canvasTouchMove(e)
    },
    { passive: false }
)
window.addEventListener(
    'gestureend',
    e => {
        e.preventDefault()
    },
    { passive: false }
)

window.addEventListener(
    'mousedown',
    e => {
        canvasMouseDown(e)
    },
    { passive: false }
)
window.addEventListener(
    'mousemove',
    e => {
        canvasMouseMove(e)
    },
    { passive: false }
)
window.addEventListener(
    'mouseup',
    e => {
        canvasMouseUp(e)
    },
    { passive: false }
)
window.addEventListener(
    'dblclick',
    e => {
        canvasMouseDblClick(e)
    },
    { passive: false }
)

async function pageSetup() {
    // Calculate the card info
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    const cardHeight = Math.floor(windowHeight / 12)
    const cardWidth = Math.floor(cardHeight * 0.75)

    let tableauSpacing = cardHeight + 20
    let tableauXOffset = cardHeight / 2
    let foundationXOffset = tableauXOffset + tableauSpacing * 3

    const cardSpacing = Math.floor((cardHeight * 3) / 10)
    const cardXOffset = Math.floor(cardSpacing * 3.75)

    initiateAudio()

    setButtonImages()

    setWindowHeight(windowHeight)
    setWindowWidth(windowWidth)
    setCardHeight(cardHeight)
    setCardWidth(cardWidth)
    setCardSpacing(cardSpacing)
    setCardYOffset(cardXOffset)
    setFoundationXOffset(foundationXOffset)

    const shuffledDeck = shuffle()
    const deckWithImages = await addImagesToDeck(shuffledDeck)
    deal(deckWithImages) // TODO: Add animation

    document?.documentElement?.style?.setProperty(
        '--currentBackgroundColor',
        appState.currentTheme.backgroundColor
    )

    document?.documentElement?.style?.setProperty(
        '--currentHighlightColor',
        appState.currentTheme.highlightColor
    )

    requestAnimationFrame(() => paint())
}
