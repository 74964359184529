export const DEFAULT_DECK = [
	{ type: 'S', value: 'K', numValue: 13, color: 'black', isFaceDown: true },
	{ type: 'C', value: 'K', numValue: 13, color: 'black', isFaceDown: true },
	{ type: 'D', value: 'K', numValue: 13, color: 'red', isFaceDown: true },
	{ type: 'H', value: 'K', numValue: 13, color: 'red', isFaceDown: true },
	{ type: 'S', value: 'Q', numValue: 12, color: 'black', isFaceDown: true },
	{ type: 'C', value: 'Q', numValue: 12, color: 'black', isFaceDown: true },
	{ type: 'D', value: 'Q', numValue: 12, color: 'red', isFaceDown: true },
	{ type: 'H', value: 'Q', numValue: 12, color: 'red', isFaceDown: true },
	{ type: 'S', value: 'J', numValue: 11, color: 'black', isFaceDown: true },
	{ type: 'C', value: 'J', numValue: 11, color: 'black', isFaceDown: true },
	{ type: 'D', value: 'J', numValue: 11, color: 'red', isFaceDown: true },
	{ type: 'H', value: 'J', numValue: 11, color: 'red', isFaceDown: true },
	{ type: 'S', value: '10', numValue: 10, color: 'black', isFaceDown: true },
	{ type: 'C', value: '10', numValue: 10, color: 'black', isFaceDown: true },
	{ type: 'D', value: '10', numValue: 10, color: 'red', isFaceDown: true },
	{ type: 'H', value: '10', numValue: 10, color: 'red', isFaceDown: true },
	{ type: 'S', value: '9', numValue: 9, color: 'black', isFaceDown: true },
	{ type: 'C', value: '9', numValue: 9, color: 'black', isFaceDown: true },
	{ type: 'D', value: '9', numValue: 9, color: 'red', isFaceDown: true },
	{ type: 'H', value: '9', numValue: 9, color: 'red', isFaceDown: true },
	{ type: 'S', value: '8', numValue: 8, color: 'black', isFaceDown: true },
	{ type: 'C', value: '8', numValue: 8, color: 'black', isFaceDown: true },
	{ type: 'D', value: '8', numValue: 8, color: 'red', isFaceDown: true },
	{ type: 'H', value: '8', numValue: 8, color: 'red', isFaceDown: true },
	{ type: 'S', value: '7', numValue: 7, color: 'black', isFaceDown: true },
	{ type: 'C', value: '7', numValue: 7, color: 'black', isFaceDown: true },
	{ type: 'D', value: '7', numValue: 7, color: 'red', isFaceDown: true },
	{ type: 'H', value: '7', numValue: 7, color: 'red', isFaceDown: true },
	{ type: 'S', value: '6', numValue: 6, color: 'black', isFaceDown: true },
	{ type: 'C', value: '6', numValue: 6, color: 'black', isFaceDown: true },
	{ type: 'D', value: '6', numValue: 6, color: 'red', isFaceDown: true },
	{ type: 'H', value: '6', numValue: 6, color: 'red', isFaceDown: true },
	{ type: 'S', value: '5', numValue: 5, color: 'black', isFaceDown: true },
	{ type: 'C', value: '5', numValue: 5, color: 'black', isFaceDown: true },
	{ type: 'D', value: '5', numValue: 5, color: 'red', isFaceDown: true },
	{ type: 'H', value: '5', numValue: 5, color: 'red', isFaceDown: true },
	{ type: 'S', value: '4', numValue: 4, color: 'black', isFaceDown: true },
	{ type: 'C', value: '4', numValue: 4, color: 'black', isFaceDown: true },
	{ type: 'D', value: '4', numValue: 4, color: 'red', isFaceDown: true },
	{ type: 'H', value: '4', numValue: 4, color: 'red', isFaceDown: true },
	{ type: 'S', value: '3', numValue: 3, color: 'black', isFaceDown: true },
	{ type: 'C', value: '3', numValue: 3, color: 'black', isFaceDown: true },
	{ type: 'D', value: '3', numValue: 3, color: 'red', isFaceDown: true },
	{ type: 'H', value: '3', numValue: 3, color: 'red', isFaceDown: true },
	{ type: 'S', value: '2', numValue: 2, color: 'black', isFaceDown: true },
	{ type: 'C', value: '2', numValue: 2, color: 'black', isFaceDown: true },
	{ type: 'D', value: '2', numValue: 2, color: 'red', isFaceDown: true },
	{ type: 'H', value: '2', numValue: 2, color: 'red', isFaceDown: true },
	{ type: 'S', value: 'A', numValue: 1, color: 'black', isFaceDown: true },
	{ type: 'C', value: 'A', numValue: 1, color: 'black', isFaceDown: true },
	{ type: 'D', value: 'A', numValue: 1, color: 'red', isFaceDown: true },
	{ type: 'H', value: 'A', numValue: 1, color: 'red', isFaceDown: true }
]
export const CARDS_LIST_PNG = [
	'./assets/cards/png/2C.png',
	'./assets/cards/png/2D.png',
	'./assets/cards/png/2H.png',
	'./assets/cards/png/2S.png',
	'./assets/cards/png/3C.png',
	'./assets/cards/png/3D.png',
	'./assets/cards/png/3H.png',
	'./assets/cards/png/3S.png',
	'./assets/cards/png/4C.png',
	'./assets/cards/png/4D.png',
	'./assets/cards/png/4H.png',
	'./assets/cards/png/4S.png',
	'./assets/cards/png/5C.png',
	'./assets/cards/png/5D.png',
	'./assets/cards/png/5H.png',
	'./assets/cards/png/5S.png',
	'./assets/cards/png/6C.png',
	'./assets/cards/png/6D.png',
	'./assets/cards/png/6H.png',
	'./assets/cards/png/6S.png',
	'./assets/cards/png/7C.png',
	'./assets/cards/png/7D.png',
	'./assets/cards/png/7H.png',
	'./assets/cards/png/7S.png',
	'./assets/cards/png/8C.png',
	'./assets/cards/png/8D.png',
	'./assets/cards/png/8H.png',
	'./assets/cards/png/8S.png',
	'./assets/cards/png/9C.png',
	'./assets/cards/png/9D.png',
	'./assets/cards/png/9H.png',
	'./assets/cards/png/9S.png',
	'./assets/cards/png/10C.png',
	'./assets/cards/png/10D.png',
	'./assets/cards/png/10H.png',
	'./assets/cards/png/10S.png',
	'./assets/cards/png/AC.png',
	'./assets/cards/png/AD.png',
	'./assets/cards/png/AH.png',
	'./assets/cards/png/AS.png',
	'./assets/cards/png/AS2.png',
	'./assets/cards/png/black_joker.png',
	'./assets/cards/png/JC.png',
	'./assets/cards/png/JC2.png',
	'./assets/cards/png/JD.png',
	'./assets/cards/png/JD2.png',
	'./assets/cards/png/JH.png',
	'./assets/cards/png/JH2.png',
	'./assets/cards/png/JS.png',
	'./assets/cards/png/JS2.png',
	'./assets/cards/png/KC.png',
	'./assets/cards/png/KC2.png',
	'./assets/cards/png/KD.png',
	'./assets/cards/png/KD2.png',
	'./assets/cards/png/KH.png',
	'./assets/cards/png/KH2.png',
	'./assets/cards/png/KS.png',
	'./assets/cards/png/KS2.png',
	'./assets/cards/png/QC.png',
	'./assets/cards/png/QC2.png',
	'./assets/cards/png/QD.png',
	'./assets/cards/png/QD2.png',
	'./assets/cards/png/QH.png',
	'./assets/cards/png/QH2.png',
	'./assets/cards/png/QS.png',
	'./assets/cards/png/QS2.png',
	'./assets/cards/png/red_joker.png'
]
export const CARDS_LIST_SVG = [
	'./assets/cards/svg/2C.svg',
	'./assets/cards/svg/2D.svg',
	'./assets/cards/svg/2H.svg',
	'./assets/cards/svg/2S.svg',
	'./assets/cards/svg/3C.svg',
	'./assets/cards/svg/3D.svg',
	'./assets/cards/svg/3H.svg',
	'./assets/cards/svg/3S.svg',
	'./assets/cards/svg/4C.svg',
	'./assets/cards/svg/4D.svg',
	'./assets/cards/svg/4H.svg',
	'./assets/cards/svg/4S.svg',
	'./assets/cards/svg/5C.svg',
	'./assets/cards/svg/5D.svg',
	'./assets/cards/svg/5H.svg',
	'./assets/cards/svg/5S.svg',
	'./assets/cards/svg/6C.svg',
	'./assets/cards/svg/6D.svg',
	'./assets/cards/svg/6H.svg',
	'./assets/cards/svg/6S.svg',
	'./assets/cards/svg/7C.svg',
	'./assets/cards/svg/7D.svg',
	'./assets/cards/svg/7H.svg',
	'./assets/cards/svg/7S.svg',
	'./assets/cards/svg/8C.svg',
	'./assets/cards/svg/8D.svg',
	'./assets/cards/svg/8H.svg',
	'./assets/cards/svg/8S.svg',
	'./assets/cards/svg/9C.svg',
	'./assets/cards/svg/9D.svg',
	'./assets/cards/svg/9H.svg',
	'./assets/cards/svg/9S.svg',
	'./assets/cards/svg/10C.svg',
	'./assets/cards/svg/10D.svg',
	'./assets/cards/svg/10H.svg',
	'./assets/cards/svg/10S.svg',
	'./assets/cards/svg/AC.svg',
	'./assets/cards/svg/AD.svg',
	'./assets/cards/svg/AH.svg',
	'./assets/cards/svg/AS.svg',
	'./assets/cards/svg/AS2.svg',
	'./assets/cards/svg/black_joker.svg',
	'./assets/cards/svg/JC.svg',
	'./assets/cards/svg/JC2.svg',
	'./assets/cards/svg/JD.svg',
	'./assets/cards/svg/JD2.svg',
	'./assets/cards/svg/JH.svg',
	'./assets/cards/svg/JH2.svg',
	'./assets/cards/svg/JS.svg',
	'./assets/cards/svg/JS2.svg',
	'./assets/cards/svg/KC.svg',
	'./assets/cards/svg/KC2.svg',
	'./assets/cards/svg/KD.svg',
	'./assets/cards/svg/KD2.svg',
	'./assets/cards/svg/KH.svg',
	'./assets/cards/svg/KH2.svg',
	'./assets/cards/svg/KS.svg',
	'./assets/cards/svg/KS2.svg',
	'./assets/cards/svg/QC.svg',
	'./assets/cards/svg/QC2.svg',
	'./assets/cards/svg/QD.svg',
	'./assets/cards/svg/QD2.svg',
	'./assets/cards/svg/QH.svg',
	'./assets/cards/svg/QH2.svg',
	'./assets/cards/svg/QS.svg',
	'./assets/cards/svg/QS2.svg',
	'./assets/cards/svg/red_joker.svg'
]
