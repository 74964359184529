import { Card } from '../Models/Card'
import { appState } from './state'
import { CardsState } from '../Models/CardsState'
import {
    stockCardX,
    stockCardY,
    tableauCardX,
    tableauCardY,
    foundationCardY,
    foundationCardX,
    wasteCardX,
    wasteCardY,
} from './allXYCoordinates'

export function assignCardCoordinates({
    stock = [],
    tableau = [[], [], [], [], [], [], []],
    waste = [],
    foundation = [[], [], [], []],
    draggedCards = [],
}: {
    stock?: Card[]
    tableau?: [Card[], Card[], Card[], Card[], Card[], Card[], Card[]]
    waste?: Card[]
    foundation?: [Card[], Card[], Card[], Card[]]
    draggedCards?: Card[]
}): CardsState {
    // 1. Assign coordinates to the cards in the stock.
    for (const i in stock) {
        const card = stock[i]
        const x = stockCardX()
        const y = stockCardY()

        card.x = x
        card.originalX = x
        card.y = y
        card.originalY = y
    }
    // 2. Assign coordinates to each card in each tableau
    for (const i in tableau) {
        const indexI = parseInt(i)
        let collapsedIndex = 0
        for (const j in tableau[i]) {
            const card = tableau[i][j]
            const indexJ = parseInt(j)

            const x = tableauCardX(indexJ)
            const y = tableauCardY(indexI)

            card.x = x
            card.originalX = x
            card.y = y
            card.originalY = y
        }
    }
    // 3. Assign Coordinates to each card in Foundation
    for (const i in foundation) {
        const indexI = parseInt(i)
        for (const j in foundation[i]) {
            const card = foundation[i][j]

            const x = foundationCardX()
            const y = foundationCardY(indexI)

            card.x = x
            card.originalX = x
            card.y = y
            card.originalY = y
        }
    }
    // 4. Assign Coordinates to each card in Waste
    for (const i in waste) {
        const card = waste[i]
        const indexI = parseInt(i)
        // if (indexI >= waste.length - NUM_CARDS_FLIPPED) {
        //     // TODO: somehow need to draw for just the last x cards
        //     const x = Math.floor(windowWidth - cardWidth * 3.5 - (indexI * cardWidth) / 2.5)
        //     const y = Math.floor(cardXOffset + (cardHeight + cardSpacing) * 7)

        //     card.x = x
        //     card.originalX = x
        //     card.y = y
        //     card.originalY = y
        // } else {
        const x = wasteCardX()
        const y = wasteCardY()

        card.x = x
        card.originalX = x
        card.y = y
        card.originalY = y
    }

    return {
        stock,
        waste,
        foundation,
        tableau,
        draggedCards,
    }
}
