import { Card } from '../Models/Card'
import { CardsState } from '../Models/CardsState'
import { postSetState } from './postSetStateFunctions'
import { AppState, CurrentAppState } from '../Models/AppState'
import { themes } from './themes'

// TODO: There should be 2 states. Card state & game state.  (should constants have their own state? (nah....))
// TODO: when mousedown, audio plays no matter the state.

export let cardState: CardsState[] = []
// export let cardState: CardsState[] = []
export let appState: AppState = {
    NUM_TABLEAU: 7,
    NUM_STOCK: 1,
    NUM_WASTE: 1,
    NUM_FOUNDATIONS: 4,
    NUM_CARDS_FLIPPED: 1,
    cardWidth: undefined,
    cardHeight: undefined,
    windowHeight: undefined,
    windowWidth: undefined,
    cardSpacing: undefined,
    cardYOffset: undefined,
    foundationXOffset: undefined,
    // currentTheme: themes.HappyPurpleTheme,
    currentTheme: themes.SoftContrastTheme,
    currentAppState: CurrentAppState.Playing,
    // currentAppState: CurrentAppState.Completed,
}

export function setCardWidth(width: number) {
    appState.cardWidth = width
}

export function setCardHeight(height: number) {
    appState.cardHeight = height
}

// export let cardsState: CardsState[] = []
export function setCardState(newState: CardsState) {
    const newCardState: CardsState[] = [...cardState, newState]
    cardState = newCardState

    postSetState()
}

export function setAppState(newState: AppState) {
    appState = newState
}

export function resetCardState() {
    cardState = []
}

let draggedCards: Card[] = []
export function getDraggedCards() {
    // console.log('fancy', getCurrentCardState().draggedCards)
    return draggedCards
    return getCurrentCardState().draggedCards
}

export function setDraggedCards(cards: Card[]) {
    const coordinatesSaved = cards.map(card => {
        if (!card.originalX || !card.originalY) {
            card.originalX = card.x
            card.originalY = card.y
        }
        return card
    })
    // console.log(coordinatesSaved)
    draggedCards = coordinatesSaved
    // FIXME:  For some reason, this is breaking sometimes. No consistent bugs,

    // getCurrentCardState().draggedCards
    // let newState = getCurrentCardState()

    // newState.draggedCards = coordinatesSaved
    // setCardState(newState)
}

export function undo() {
    if (cardState.length > 1) {
        cardState.pop()
    }
}

export function setWindowHeight(height: number) {
    appState.windowHeight = height
}

export function setWindowWidth(width: number) {
    appState.windowWidth = width
}

export function setCardSpacing(spacing: number) {
    appState.cardSpacing = spacing
}
export function setCardYOffset(offset: number) {
    appState.cardYOffset = offset
}
export function setFoundationXOffset(offset: number) {
    appState.foundationXOffset = offset
}

export function checkForWin(): boolean {
    for (const i in getCurrentCardState().foundation) {
        const foundation = getCurrentCardState().foundation[i]
        if (foundation.length !== 13) {
            return false
        }
    }
    return true
}

export let undoImageSource: HTMLOrSVGImageElement
export function setUndoImageSource(src: HTMLOrSVGImageElement) {
    undoImageSource = src
}

export let newGameButtonImageSource: HTMLOrSVGImageElement
export function setNewGameButtonImageSource(src) {
    newGameButtonImageSource = src
}

export let menuButtonImageSource: HTMLOrSVGImageElement
export function setMenuImageSource(src) {
    menuButtonImageSource = src
}

export function getCurrentCardState(): CardsState {
    return cardState[cardState.length - 1]
}
