import { Theme } from './Theme'

export class AppState {
    NUM_TABLEAU: number
    NUM_STOCK: number
    NUM_WASTE: number
    NUM_FOUNDATIONS: number
    NUM_CARDS_FLIPPED: number
    cardWidth: number
    cardHeight: number
    windowHeight: number
    windowWidth: number
    cardSpacing: number
    cardYOffset: number
    foundationXOffset: number
    currentTheme: Theme
    currentAppState: CurrentAppState
}

export enum CurrentAppState {
    Playing,
    Menu,
    Completed,
}
