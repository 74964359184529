import { highlightRect, drawRect, drawImage, drawOutline } from './canvasMethods'
import { appState } from './state'
import {
    tableauPlaceholderX,
    tableauPlaceholderY,
    foundationPlaceholderX,
    foundationPlaceholderY,
    wastePlaceholderX,
    wastePlaceholderY,
    stockPlaceholderX,
    stockPlaceholderY,
} from './allXYCoordinates'

export function drawTableauPlaceHolder(ctx: CanvasRenderingContext2D) {
    for (let i = 0; i < appState.NUM_TABLEAU; i++) {
        const x = tableauPlaceholderX()
        const y = tableauPlaceholderY(i)

        drawOutline(
            x + appState.cardWidth / 6,
            y + appState.cardWidth / 3,
            appState.cardWidth / 1.5,
            appState.cardWidth / 1.5,
            ctx
        )
    }
}
export function drawFoundationPlaceHolder(ctx: CanvasRenderingContext2D) {
    for (let i = 0; i < appState.NUM_FOUNDATIONS; i++) {
        const x = foundationPlaceholderX()
        const y = foundationPlaceholderY(i)

        drawOutline(
            x + appState.cardWidth / 6,
            y + appState.cardWidth / 3,
            appState.cardWidth / 1.5,
            appState.cardWidth / 1.5,
            ctx
        )
    }
}

export function drawWastePlaceHolder(ctx: CanvasRenderingContext2D) {
    const x = wastePlaceholderX()
    const y = wastePlaceholderY()

    drawOutline(
        x + appState.cardWidth / 6,
        y + appState.cardWidth / 3,
        appState.cardWidth / 1.5,
        appState.cardWidth / 1.5,
        ctx
    )
}

export function drawStockPlaceHolder(ctx: CanvasRenderingContext2D) {
    const x = stockPlaceholderX()
    const y = stockPlaceholderY()

    drawOutline(
        x + appState.cardWidth / 6,
        y + appState.cardWidth / 3,
        appState.cardWidth / 1.5,
        appState.cardWidth / 1.5,
        ctx
    )
}
