export function initiateAudio() {
    const shuffleAudio = new Audio('./assets/audio/shuffle.m4a')
    const liftCardAudio = new Audio('./assets/audio/liftCard.m4a')
    const placeCardAudio = new Audio('./assets/audio/placeCard.m4a')

    setShuffleCardAudio(shuffleAudio)
    setLiftCardAudio(liftCardAudio)
    setPlaceCardAudio(placeCardAudio)
}

export let shuffleCardAudio: HTMLAudioElement
function setShuffleCardAudio(audio) {
    shuffleCardAudio = audio
}
export let liftCardAudio: HTMLAudioElement
function setLiftCardAudio(audio: HTMLAudioElement) {
    liftCardAudio = audio
}
export let placeCardAudio: HTMLAudioElement
function setPlaceCardAudio(audio: HTMLAudioElement) {
    placeCardAudio = audio
}

export function playSoundEffect(audio: HTMLAudioElement) {
    shuffleCardAudio.pause()
    shuffleCardAudio.currentTime = 0
    liftCardAudio.pause()
    liftCardAudio.currentTime = 0
    placeCardAudio.pause()
    placeCardAudio.currentTime = 0

    // Tip from Stian multiple similar sounds and vary them.

    // audio.play()
}
