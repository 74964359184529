import { Card } from '../Models/Card'
import { cardState, appState, getCurrentCardState } from './state'
import { foundationCardX, foundationCardY, tableauCardX, tableauCardY } from './allXYCoordinates'
// TODO: Change Calculations if needed.
export function cardIndexTableau(incCard: Card): [number, number] {
    const currentState = getCurrentCardState()
    for (let i = currentState.tableau.length - 1; i >= 0; i--) {
        if (currentState.tableau[i].length > 0) {
            for (let j = currentState.tableau[i].length - 1; j >= 0; j--) {
                const curCard = currentState.tableau[i][j]
                if (curCard && incCard && incCard.type === curCard.type && incCard.value === curCard.value) {
                    return [i, j]
                }
            }
        }
    }
}

export function cardIndexFoundation(incCard: Card): [number, number] {
    const currentState = getCurrentCardState()
    for (let i = currentState.foundation.length - 1; i >= 0; i--) {
        if (currentState.foundation[i].length > 0) {
            for (let j = currentState.foundation[i].length - 1; j >= 0; j--) {
                if (currentState.foundation[i][j]) {
                    const curCard = currentState.foundation[i][j]
                    if (
                        curCard &&
                        incCard &&
                        incCard.type === curCard.type &&
                        incCard.value === curCard.value
                    ) {
                        return [i, j]
                    }
                }
            }
        }
    }
}

export function cardIndexWaste(incCard: Card): number {
    const currentState = getCurrentCardState()
    for (let i = currentState.waste.length - 1; i >= 0; i--) {
        const curCard = currentState.waste[i]

        if (curCard && incCard && incCard.type === curCard.type && incCard.value === curCard.value) {
            return i
        }
    }
}

export function cardIndexStock(incCard: Card): number {
    const currentState = getCurrentCardState()
    for (let i = currentState.stock.length - 1; i >= 0; i--) {
        const curCard = currentState.stock[i]

        if (curCard && incCard && incCard.type === curCard.type && incCard.value === curCard.value) {
            return i
        }
    }
}

export function isCardOverFoundation(x: number, y: number): number {
    for (let i = 0; i < appState.NUM_FOUNDATIONS; i++) {
        const foundationX = foundationCardX()
        const foundationY = foundationCardY(i)

        if (
            x > foundationX &&
            x < appState.cardWidth + foundationX &&
            y > foundationY &&
            y < foundationY + appState.cardHeight
        ) {
            return i
        }
    }
}

export function isCardOverTableau(x: number, y: number): number {
    for (let i = 0; i < appState.NUM_TABLEAU; i++) {
        // const tableauX = tableauCardX(i)
        // const tableauY = tableauCardY(i)
        // TODO: Put these back & calculate better
        const tableauX = Math.floor(appState.windowWidth - appState.cardWidth * 3)
        const tableauY = Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * i)

        if (x < appState.cardWidth + tableauX && y > tableauY && y < tableauY + appState.cardHeight) {
            return i
        }
    }
}
