import { drawImage, drawText } from './canvasMethods'
import { menuButtonImageSource, newGameButtonImageSource, undoImageSource, appState } from './state'
import {
    undoButtonX,
    undoButtonY,
    menuButtonX,
    menuButtonY,
    newGameButtonTextX,
    newGameButtonX,
    newGameButtonY,
    newGameButtonTextY,
} from './allXYCoordinates'

export function drawUndoButton(ctx: CanvasRenderingContext2D) {
    const x = undoButtonX()
    const y = undoButtonY()

    drawImage(x, y, appState.cardWidth, appState.cardWidth, undoImageSource, ctx)
}

export function drawMenuButton(ctx: CanvasRenderingContext2D) {
    const x = menuButtonX()
    const y = menuButtonY()

    drawImage(x, y, appState.cardWidth, appState.cardWidth, menuButtonImageSource, ctx)
}

export function drawNewGameButton(ctx: CanvasRenderingContext2D) {
    const x = newGameButtonX()
    const y = newGameButtonY()

    drawImage(x, y, appState.cardWidth, appState.cardWidth, newGameButtonImageSource, ctx)
}
export function drawNewGameButtonText(ctx: CanvasRenderingContext2D) {
    const fontSize = 5
    const x = newGameButtonTextX()
    const y = newGameButtonTextY()

    drawText('New Game', x, y, fontSize, ctx)
}
