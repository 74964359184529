import {
    getSelectedCard,
    getStockSelected,
    getUndoButtonSelected,
    getSelectedNewGameButtonSelected,
    getSelectedFullScreenButton,
    getMenuButtonSelected,
} from './getClickSelection'
import {
    cardIndexFoundation,
    cardIndexStock,
    cardIndexTableau,
    cardIndexWaste,
    isCardOverFoundation,
    isCardOverTableau,
} from './findCardIndex'
import {
    cardState,
    setDraggedCards,
    getDraggedCards,
    undo,
    checkForWin,
    setCardState,
    resetCardState,
    appState,
    getCurrentCardState,
    setAppState,
} from './state'
import { placeCardAudio, liftCardAudio, playSoundEffect } from './audioFunctions'
import { CardsState } from '../Models/CardsState'
import { paint } from './paint'
import cloneDeep from 'lodash.clonedeep'
import { assignCardCoordinates } from './assignCardCoordinates'
import { shuffle, deal, addImagesToDeck } from './setupCards'
import { requestFullScreen } from './requestFullScreen'
import { CurrentAppState } from '../Models/AppState'

let startY
let startX
let myLatestTap

export function canvasMouseDown(e: MouseEvent) {
    e.preventDefault()
    console.log('mousedown on ', e.x, e.y)
    const click = {
        changedTouches: [{ clientX: e.x, clientY: e.y }],
        preventDefault: e.preventDefault,
    }
    // canvasTouchStart(click)
}

export function canvasMouseMove(e) {
    e.preventDefault()
    // const thing.changedTouches[0].clientX = e.X
    // const thing.changedTouches[0].clientY = e.Y

    const click = {
        changedTouches: [{ clientX: e.X, clientY: e.Y }],
    }
    canvasTouchMove(click)
}

export function canvasMouseUp(e) {
    e.preventDefault()
    // console.log('mouseup')
    // setDraggedCards([])
    const click = {
        changedTouches: [{ clientX: e.X, clientY: e.Y }],
    }
    canvasTouchEnd(click)
}

export function canvasMouseDblClick(e) {
    e.preventDefault()
    console.log('double click')
}

export function canvasTouchStart(e: TouchEvent) {
    e.preventDefault()
    const touch = e.changedTouches[0]

    const currentTap = new Date().getTime()
    const timeSince = currentTap - myLatestTap
    if (timeSince < 600 && timeSince > 0) {
        // double tap
        canvasTouchDoubleTap(e)
    }

    myLatestTap = new Date().getTime()

    startX = touch.clientX
    startY = touch.clientY

    const selectedCard = getSelectedCard(touch.clientX, touch.clientY)
    // console.log({selectedCard})
    const selectedStock: boolean = getStockSelected(touch.clientX, touch.clientY)
    // console.log({ selectedStock })
    const selectedUndo: boolean = getUndoButtonSelected(touch.clientX, touch.clientY)
    // console.log({ selectedUndo })
    const selectedNewGameButton: boolean = getSelectedNewGameButtonSelected(touch.clientX, touch.clientY)
    // console.log({ selectedNewGame })
    // const selectedFullScreenButton: boolean = getSelectedFullScreenButton(touch.clientX, touch.clientY)
    // console.log({ selectedFullScreenButton })
    const selectedMenuButton: boolean = getMenuButtonSelected(touch.clientX, touch.clientY)
    // console.log({ selectedMenuButton })

    // 1. If the card is selected, make it the current dragging card
    if (appState.currentAppState === CurrentAppState.Playing && selectedCard !== undefined) {
        playSoundEffect(liftCardAudio)

        const tableauIndex = cardIndexTableau(selectedCard)
        const foundIndex = cardIndexFoundation(selectedCard)
        const stockIndex = cardIndexStock(selectedCard)
        const wasteIndex = cardIndexWaste(selectedCard)
        if (tableauIndex !== undefined) {
            const [ti, tj] = tableauIndex
            if (!selectedCard.isFaceDown) {
                let newState: CardsState = cloneDeep(getCurrentCardState())
                setDraggedCards(newState.tableau[ti].slice(tj))
                const newTableau = newState.tableau[ti].slice(0, tj)
                newState.tableau[ti] = newTableau
                setCardState(newState)
            } else if (selectedCard.isFaceDown && tj === getCurrentCardState().tableau[ti].length - 1) {
                let newState: CardsState = cloneDeep(getCurrentCardState())
                let newCard = newState.tableau[ti][tj]
                newCard.isFaceDown = false
                newState.tableau[ti][tj] = newCard
                setCardState(newState)
            }
        } else if (foundIndex !== undefined) {
            const [fi, fj] = foundIndex
            if (!selectedCard.isFaceDown) {
                const newState: CardsState = cloneDeep(getCurrentCardState())
                setDraggedCards(newState.foundation[fi].slice(fj))
                const newFoundation = newState.foundation[fi].slice(0, fj)
                newState.foundation[fi] = newFoundation
                setCardState(newState)
            }
        } else if (wasteIndex !== undefined) {
            if (!selectedCard.isFaceDown) {
                const newState: CardsState = cloneDeep(getCurrentCardState())
                setDraggedCards(newState.waste.slice(wasteIndex))
                const newWaste = newState.waste.slice(0, wasteIndex)
                newState.waste = newWaste
                setCardState(newState)
            }
        } else {
        }
    }

    if (appState.currentAppState === CurrentAppState.Playing && selectedStock) {
        // if we tapped on stock.... we can't grab one of those cards.. we flip it.
        const newState: CardsState = cloneDeep(getCurrentCardState())
        if (newState.stock.length > 0) {
            // something in stock, pop and flip
            const moveToWaste = newState.stock.slice(-appState.NUM_CARDS_FLIPPED)
            const newStock = newState.stock.slice(0, -appState.NUM_CARDS_FLIPPED)

            const flippedWasteCards = moveToWaste.map(card => {
                card.isFaceDown = false
                return card
            })

            const newWaste = [...newState.waste, ...flippedWasteCards]

            const newStateWithCoordinates: CardsState = assignCardCoordinates({
                stock: newStock,
                tableau: newState.tableau,
                waste: newWaste,
                foundation: newState.foundation,
            })
            setCardState(newStateWithCoordinates)
        } else {
            // nothing in stock, reset the hand
            const moveToStock = newState.waste.map(card => {
                card.isFaceDown = true

                return card
            })
            const newWaste = []
            const newStock = moveToStock.reverse()
            newState.waste = newWaste
            newState.stock = newStock

            const newStateWithCoordinates: CardsState = assignCardCoordinates({
                stock: newState.stock,
                tableau: newState.tableau,
                waste: newState.waste,
                foundation: newState.foundation,
            })
            setCardState(newStateWithCoordinates)
        }
    }

    if (appState.currentAppState === CurrentAppState.Playing && selectedUndo) {
        undo()
    }

    if ((appState.currentAppState === CurrentAppState.Playing || appState.currentAppState === CurrentAppState.Completed) && selectedNewGameButton) {
        let currentAppState = appState
        currentAppState.currentAppState = CurrentAppState.Playing

        setAppState(currentAppState)
        resetCardState()
        const shuffledDeck = shuffle()
        deal(shuffledDeck)
        requestAnimationFrame(() => paint())
    }

    // if (selectedFullScreenButton) {
    //     requestFullScreen()
    // }

    if (false && selectedMenuButton) {
        let newAppState = cloneDeep(appState)
        if (newAppState.currentAppState === CurrentAppState.Playing) {
            newAppState.currentAppState = CurrentAppState.Menu
            setAppState(newAppState)
        } else if (newAppState.currentAppState === CurrentAppState.Menu) {
            newAppState.currentAppState = CurrentAppState.Playing
            setAppState(newAppState)
        }
    }

    requestAnimationFrame(() => paint())
}

export function canvasTouchMove(e) {
    e.preventDefault()
    const touch = e.changedTouches[0]

    // document.getElementById('mouseCoordinates').innerText = `X: ${touch.clientX}
    // Y: ${touch.clientY}`
    const touchX = touch.clientX
    const touchY = touch.clientY
    const distanceX = touchX - startX
    const distanceY = touchY - startY

    if (getDraggedCards().length > 0) {
        const movedCards = getDraggedCards().map(card => {
            const newX = card.originalX + distanceX
            const newY = card.originalY + distanceY
            card.x = Math.floor(newX)
            card.y = Math.floor(newY)
            return card
        })
        setDraggedCards(movedCards)
    }

    requestAnimationFrame(() => paint())
}

export function canvasTouchEnd(e) {
    e.preventDefault()
    const touch = e.changedTouches[0]

    startX = undefined
    startY = undefined
    // console.log('touchEnd')

    const draggedCards = getDraggedCards()
    if (draggedCards && draggedCards.length > 0) {
        playSoundEffect(placeCardAudio)

        // console.log(draggedCards)
        // figure out what we're dragged over.
        // We only care about being over a foundation or a tableau.

        const isOverFoundation = isCardOverFoundation(touch.clientX, touch.clientY)
        // console.log({ isOverFoundation })
        // 1. are we over a foundation?
        // 2. can we place our card on the top card?
        const isOverTableau = isCardOverTableau(touch.clientX, touch.clientY)
        // console.log({ isOverTableau })

        if (isOverFoundation !== undefined) {
            const newState: CardsState = cloneDeep(getCurrentCardState())
            const bottomCardOnDraggedCards = draggedCards[0]
            const topCardOnFoundation =
                newState.foundation[isOverFoundation][newState.foundation[isOverFoundation].length - 1]
            if (
                (newState.foundation[isOverFoundation].length === 0 &&
                    bottomCardOnDraggedCards.numValue === 1) ||
                (topCardOnFoundation &&
                    !topCardOnFoundation.isFaceDown &&
                    draggedCards.length === 1 &&
                    bottomCardOnDraggedCards.color === topCardOnFoundation.color &&
                    bottomCardOnDraggedCards.type === topCardOnFoundation.type &&
                    bottomCardOnDraggedCards.numValue === topCardOnFoundation.numValue + 1)
            ) {
                newState.foundation[isOverFoundation].push(draggedCards[0])

                const newStateWithCoordinates: CardsState = assignCardCoordinates({
                    stock: newState.stock,
                    tableau: newState.tableau,
                    waste: newState.waste,
                    foundation: newState.foundation,
                })
                undo()
                setCardState(newStateWithCoordinates)
            } else {
                undo()
                requestAnimationFrame(() => paint())
            }
        }
        // 1.are we over a tableau
        // can we place our card onto the top card there?
        else if (isOverTableau !== undefined) {
            let newState: CardsState = cloneDeep(getCurrentCardState())
            const bottomCardOnDraggedCards = draggedCards[0]
            const topCardOnTableau = newState.tableau[isOverTableau][newState.tableau[isOverTableau].length - 1]
            if (
                topCardOnTableau &&
                !topCardOnTableau.isFaceDown &&
                newState.tableau[isOverTableau].length > 0 &&
                bottomCardOnDraggedCards.color !== topCardOnTableau.color &&
                bottomCardOnDraggedCards.numValue === topCardOnTableau.numValue - 1
            ) {
                const newTableau = [...newState.tableau[isOverTableau], ...draggedCards]

                newState.tableau[isOverTableau] = newTableau

                const newStateWithCoordinates: CardsState = assignCardCoordinates({
                    stock: newState.stock,
                    tableau: newState.tableau,
                    waste: newState.waste,
                    foundation: newState.foundation,
                })
                undo()
                setCardState(newStateWithCoordinates)
            } else if (
                newState.tableau[isOverTableau].length === 0 &&
                bottomCardOnDraggedCards.numValue === 13
            ) {
                const newTableau = [...newState.tableau[isOverTableau], ...draggedCards]
                newState.tableau[isOverTableau] = newTableau
                const newStateWithCoordinates: CardsState = assignCardCoordinates({
                    stock: newState.stock,
                    tableau: newState.tableau,
                    waste: newState.waste,
                    foundation: newState.foundation,
                })
                undo()
                setCardState(newStateWithCoordinates)
            } else {
                undo()
                requestAnimationFrame(() => paint())
            }
        } else {
            // FIXME: When hitting `undo` the card doesn't go back to its original spot... we messed up somewhere, you've got to hit it twice.
            // / Best current option. on drop, undo, THEN push onto the new state...

            undo()
            requestAnimationFrame(() => paint())
        }
    }
    setDraggedCards([])

    const hasWon = checkForWin()
    if (hasWon) {
        // console.log('hey you won')
        // // alert('Congratulations! You won! ')

        let currentAppState = appState
        currentAppState.currentAppState = CurrentAppState.Completed
        setAppState(currentAppState)
    }

    requestAnimationFrame(() => paint())
}

export function canvasTouchDoubleTap(e) {
    e.preventDefault()
    const touch = e.changedTouches[0]
    // 1. Determine the card
    const selectedCard = getSelectedCard(touch.clientX, touch.clientY)
    let newState: CardsState = cloneDeep(getCurrentCardState())

    // 2. For all foundations:
    if (appState.currentAppState === CurrentAppState.Playing && selectedCard !== undefined) {
        const isOverTableau = isCardOverTableau(touch.clientX, touch.clientY)
        const isOverWaste = cardIndexWaste(selectedCard)
        if (
            isOverTableau !== undefined &&
            newState.tableau[isOverTableau][newState.tableau[isOverTableau].length - 1].numValue ===
                selectedCard.numValue &&
            newState.tableau[isOverTableau][newState.tableau[isOverTableau].length - 1].color ===
                selectedCard.color &&
            !selectedCard.isFaceDown
        ) {
            const tableauIndex = cardIndexTableau(selectedCard)
            const [ti, tj] = tableauIndex

            for (const i in newState.foundation) {
                const foundation = newState.foundation[i]
                const topCardOnFoundation = newState.foundation[i][newState.foundation[i].length - 1]

                if (selectedCard.numValue === 1 && foundation.length === 0) {
                    newState.foundation[i].push(selectedCard)

                    const newTableau = newState.tableau[ti].slice(0, tj)
                    newState.tableau[ti] = newTableau

                    const newStateWithCoordinates: CardsState = assignCardCoordinates({
                        stock: newState.stock,
                        tableau: newState.tableau,
                        waste: newState.waste,
                        foundation: newState.foundation,
                    })
                    setCardState(newStateWithCoordinates)

                    break
                } else if (
                    topCardOnFoundation !== undefined &&
                    !topCardOnFoundation.isFaceDown &&
                    selectedCard.color === topCardOnFoundation.color &&
                    selectedCard.type === topCardOnFoundation.type &&
                    selectedCard.numValue === topCardOnFoundation.numValue + 1
                ) {
                    newState.foundation[i].push(selectedCard)
                    const newTableau = newState.tableau[ti].slice(0, tj)
                    newState.tableau[ti] = newTableau

                    const newStateWithCoordinates: CardsState = assignCardCoordinates({
                        stock: newState.stock,
                        tableau: newState.tableau,
                        waste: newState.waste,
                        foundation: newState.foundation,
                    })
                    setCardState(newStateWithCoordinates)

                    break
                }
            }
        }
        if (isOverWaste !== undefined && !selectedCard.isFaceDown) {
            for (const i in newState.foundation) {
                const foundation = newState.foundation[i]
                const topCardOnFoundation = newState.foundation[i][newState.foundation[i].length - 1]

                if (selectedCard.numValue === 1 && foundation.length === 0) {
                    let card = newState.waste.pop()
                    newState.foundation[i].push(card)

                    const newStateWithCoordinates: CardsState = assignCardCoordinates({
                        stock: newState.stock,
                        tableau: newState.tableau,
                        waste: newState.waste,
                        foundation: newState.foundation,
                    })
                    setCardState(newStateWithCoordinates)

                    break
                } else if (
                    topCardOnFoundation &&
                    selectedCard.color === topCardOnFoundation.color &&
                    selectedCard.type === topCardOnFoundation.type &&
                    selectedCard.numValue === topCardOnFoundation.numValue + 1
                ) {
                    let card = newState.waste.pop()
                    newState.foundation[i].push(card)

                    const newStateWithCoordinates: CardsState = assignCardCoordinates({
                        stock: newState.stock,
                        tableau: newState.tableau,
                        waste: newState.waste,
                        foundation: newState.foundation,
                    })
                    setCardState(newStateWithCoordinates)

                    break
                }
            }
        }
    }

    // 3. If card is `A`, toss it into the first empty foundation
    // 4. If not, check the top card on each foundation (the last card)
    // 5. If the card is the same type and exactly 1 greater, toss it onto the foundation.
}
