import { paint } from './paint'
import {
    setUndoImageSource,
    setNewGameButtonImageSource,
    setMenuImageSource,
    setCardState,
    appState,
} from './state'
import { shuffleCardAudio, playSoundEffect } from './audioFunctions'
import { DEFAULT_DECK } from '../static/assets/constants'
import { assignCardCoordinates } from './assignCardCoordinates'
import { Card } from '../Models/Card'

export function deal(deck) {
    let tableau: [Card[], Card[], Card[], Card[], Card[], Card[], Card[]] = [[], [], [], [], [], [], []]
    let stock = [...deck].reverse()
    // Move hand cards to tableau. Set the last card in each tableau to be face up
    for (let i = 0; i < 7; i++) {
        if (!tableau[i]) {
            tableau[i] = []
        }
        let activeTableau = tableau[i]
        for (let j = 0; j <= i; j++) {
            activeTableau.push(stock.pop())
            if (j === i) {
                activeTableau[activeTableau.length - 1].isFaceDown = false
            }
        }
    }
    // We now know the rest of the cards in hand will remain face up cards
    for (let k = 0; k < stock.length; k++) {
        stock[k].isFaceDown = true
    }
    const deckWithCoordinates = assignCardCoordinates({ stock, tableau })
    // hand contains all waste and stock
    setCardState(deckWithCoordinates)
}

/**
 * Fisher–Yates shuffle Algorithm works in O(n) time complexity. The assumption
 * here is, we are given a function rand() that generates random number in O(1) time.
 * @return {Array} Shuffled version of DEFAULT_DECK
 */
export function shuffle() {
    playSoundEffect(shuffleCardAudio)

    let defaultDeck = [...DEFAULT_DECK]
    let deck = defaultDeck.map(card => {
        card.isFaceDown = true
        return card
    })
    for (let i = deck.length - 1; i > 0; i--) {
        let randomIdx = Math.floor(Math.random() * i)
        let temp = deck[i]

        deck[i] = deck[randomIdx]
        deck[randomIdx] = temp
    }
    return deck
}

export async function addImagesToDeck(deck) {
    const newDeck = deck.map(card => {
        const image = new Image()
        image.src = `./assets/cards/${appState.currentTheme.cardNames}/${card.value}${card.type}.svg`

        card.img = image
        image.onload = () => requestAnimationFrame(() => paint())
        return card
    })
    return newDeck
}

export function setButtonImages(): void {
    // Setup Undo Button
    const undoImage = new Image()
    undoImage.src = `./assets/buttons/${appState.currentTheme.buttonNames}/undo.svg`

    // Setup New Game Button
    const newGameButton = new Image()
    newGameButton.src = `./assets/buttons/${appState.currentTheme.buttonNames}/plus.svg`

    // Setup Menu Button
    const menuButton = new Image()
    menuButton.src = `./assets/buttons/${appState.currentTheme.buttonNames}/menu.svg`

    setUndoImageSource(undoImage)
    setMenuImageSource(menuButton)
    setNewGameButtonImageSource(newGameButton)
}
