import { Card } from '../Models/Card'
import { appState } from './state'

export function drawRect(
    startX: number,
    startY: number,
    width: number,
    height: number,
    ctx: CanvasRenderingContext2D
) {
    ctx.fillStyle = appState.currentTheme.fillColor
    ctx.strokeStyle = appState.currentTheme.highlightColor
    ctx.fillRect(Math.floor(startX), Math.floor(startY), Math.floor(width), Math.floor(height))
}

export function drawOutline(
    startX: number,
    startY: number,
    width: number,
    height: number,
    ctx: CanvasRenderingContext2D
) {
    const borderRadius = 7
    ctx.beginPath()
    ctx.moveTo(startX + borderRadius, startY)
    ctx.lineTo(startX + width - borderRadius, startY)
    ctx.quadraticCurveTo(startX + width, startY, startX + width, startY + borderRadius)
    ctx.lineTo(startX + width, startY + height - borderRadius)
    ctx.quadraticCurveTo(startX + width, startY + height, startX + width - borderRadius, startY + height)
    ctx.lineTo(startX + borderRadius, startY + height)
    ctx.quadraticCurveTo(startX, startY + height, startX, startY + height - borderRadius)
    ctx.lineTo(startX, startY + borderRadius)
    ctx.quadraticCurveTo(startX, startY, startX + borderRadius, startY)
    ctx.closePath()
    ctx.fillStyle = appState.currentTheme.placeholderColor
    ctx.fill()
}

export function highlightRect(
    startX: number,
    startY: number,
    width: number,
    height: number,
    ctx: CanvasRenderingContext2D
) {
    ctx.strokeStyle = appState.currentTheme.highlightColor
    ctx.lineWidth = 2
    ctx.strokeRect(Math.floor(startX), Math.floor(startY), Math.floor(width), Math.floor(height))
}

export function drawImage(
    startX: number,
    startY: number,
    width: number,
    height: number,
    backgroundImage: CanvasImageSource,
    ctx: CanvasRenderingContext2D
) {
    ctx.drawImage(
        backgroundImage,
        Math.floor(startX),
        Math.floor(startY),
        Math.floor(width),
        Math.floor(height)
    )
}
export function drawCardBack(
    startX: number,
    startY: number,
    width: number,
    height: number,
    ctx: CanvasRenderingContext2D
) {
    const borderRadius = 7
    ctx.beginPath()
    ctx.moveTo(startX + borderRadius, startY)
    ctx.lineTo(startX + width - borderRadius, startY)
    ctx.quadraticCurveTo(startX + width, startY, startX + width, startY + borderRadius)
    ctx.lineTo(startX + width, startY + height - borderRadius)
    ctx.quadraticCurveTo(startX + width, startY + height, startX + width - borderRadius, startY + height)
    ctx.lineTo(startX + borderRadius, startY + height)
    ctx.quadraticCurveTo(startX, startY + height, startX, startY + height - borderRadius)
    ctx.lineTo(startX, startY + borderRadius)
    ctx.quadraticCurveTo(startX, startY, startX + borderRadius, startY)
    ctx.closePath()
    ctx.fillStyle = appState.currentTheme.cardBackColor
    ctx.fill()
}

export function drawCard(card: Card, ctx: CanvasRenderingContext2D) {
    if (card.isFaceDown) {
        // drawRect(card.x, card.y, cardWidth, cardHeight, ctx)
        drawCardBack(card.x, card.y, appState.cardWidth, appState.cardHeight, ctx)
    } else {
        drawImage(card.x, card.y, appState.cardWidth, appState.cardHeight, card.img, ctx)
    }
    drawCardBorder(card.x, card.y, appState.cardWidth, appState.cardHeight, ctx)
}

function writeFrameName(frame, ctx: CanvasRenderingContext2D, fontSize: number) {
    ctx.fillStyle = appState.currentTheme.textColor
    ctx.font = `${Math.floor(fontSize)}px Arial` // basing text size on monitor width
    // scaling spacing from frame off of width (65 just got us close to what looked right)
    ctx.fillText(frame.name, frame.startX, Math.floor(frame.startY) - frame.width / 65)
}

export function drawText(text, startX, startY, fontSize: number, ctx) {
    ctx.save()

    ctx.fillStyle = appState.currentTheme.textColor
    // ctx.font = `${Math.floor(fontSize)}px Arial` // basing text size on monitor width
    ctx.font = `${Math.floor(fontSize)}vw Avenir Book` // basing text size on monitor width
    ctx.shadowOffsetX = 1
    ctx.shadowOffsetY = 1
    ctx.shadowBlur = 2
    ctx.shadowColor = appState.currentTheme.textColorShadow
    // ctx.fillText.
    ctx.fillText(text, startX, startY)
    ctx.restore()
}

export function drawCardBorder(
    startX: number,
    startY: number,
    width: number,
    height: number,
    ctx: CanvasRenderingContext2D
) {
    const borderRadius = 7
    ctx.beginPath()
    ctx.moveTo(startX + borderRadius, startY)
    ctx.lineTo(startX + width - borderRadius, startY)
    ctx.quadraticCurveTo(startX + width, startY, startX + width, startY + borderRadius)
    ctx.lineTo(startX + width, startY + height - borderRadius)
    ctx.quadraticCurveTo(startX + width, startY + height, startX + width - borderRadius, startY + height)
    ctx.lineTo(startX + borderRadius, startY + height)
    ctx.quadraticCurveTo(startX, startY + height, startX, startY + height - borderRadius)
    ctx.lineTo(startX, startY + borderRadius)
    ctx.quadraticCurveTo(startX, startY, startX + borderRadius, startY)
    ctx.closePath()
    ctx.lineWidth = 2
    ctx.strokeStyle = appState.currentTheme.backgroundColor
    ctx.stroke()
}
