'use-strict'

import { drawImage, drawRect, highlightRect } from './canvasMethods'
import { drawDraggedCards, drawFoundation, drawStock, drawTableau, drawWaste } from './drawCards'
import {
    drawFoundationPlaceHolder,
    drawStockPlaceHolder,
    drawTableauPlaceHolder,
    drawWastePlaceHolder,
} from './drawPlaceholderOutlines'
import { drawUndoButton, drawMenuButton, drawNewGameButton, drawNewGameButtonText } from './drawButtons'
import { appState } from './state'
import { CurrentAppState, AppState } from '../Models/AppState'
import { drawCompletedGameAlert } from './drawCompletedGameAlert'

export function paint() {
    const canvas: any = document.getElementById('canvas')
    canvas.style.backgroundColor = appState.currentTheme.backgroundColor
    const dpr = window.devicePixelRatio || 1
    const ctx = canvas.getContext('2d')
    canvas.width = appState.windowWidth * dpr
    canvas.height = appState.windowHeight * dpr
    canvas.style.width = `${appState.windowWidth}px`
    canvas.style.height = `${appState.windowHeight}px`
    ctx.imageSmoothingEnabled = false
    ctx.scale(dpr, dpr)

    const gameCompleteMenu = document.querySelector('#gameCompleteMenu')
    const bobRossDoingTheFlossLikeABoss = document.querySelector('#BobRossDoingTheFlossLikeABoss')
    const menu = document.querySelector('#menu')

    switch (appState.currentAppState) {
        // TODO: somehow have a list of which state each item is drawable in.
        // * For Example: new game can be drawn on playing, menu & completed
        // *** while undo, is only drawable on playing
        case CurrentAppState.Playing:
            menu.classList.add('hidden')
            canvas.classList.remove('hidden')
            gameCompleteMenu.classList.add('hidden')
            bobRossDoingTheFlossLikeABoss.classList.add('hidden')
            drawFoundationPlaceHolder(ctx)
            drawTableauPlaceHolder(ctx)
            drawWastePlaceHolder(ctx)
            drawStockPlaceHolder(ctx)

            drawUndoButton(ctx)
            // drawNewGameButton(ctx)
            drawNewGameButtonText(ctx)
            // drawMenuButton(ctx)

            drawFoundation(ctx)
            drawTableau(ctx)
            drawWaste(ctx)
            drawStock(ctx)

            drawDraggedCards(ctx)
            break
        case CurrentAppState.Completed:
            menu.classList.add('hidden')
            canvas.classList.remove('hidden')
            gameCompleteMenu.classList.remove('hidden')
            bobRossDoingTheFlossLikeABoss.classList.remove('hidden')

            // drawCompletedGameAlert(ctx)
            drawNewGameButtonText(ctx)

            break
        case CurrentAppState.Menu:
            menu.classList.remove('hidden')
            canvas.classList.remove('hidden')
            gameCompleteMenu.classList.add('hidden')
            bobRossDoingTheFlossLikeABoss.classList.add('hidden')

            // drawUndoButton(ctx)
            // drawNewGameButton(ctx)
            // drawNewGameButtonText(ctx)
            drawMenuButton(ctx)

            break
    }
}
