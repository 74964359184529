import { appState } from './state'

export function stockCardX(): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 1.5)
}
export function stockCardY(): number {
    return Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * 7)
}

export function tableauCardX(i: number): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 3 - (i * appState.cardWidth) / 3)
}
export function tableauCardY(i: number): number {
    return Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * i)
}

export function foundationCardX(): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 1.5)
}
export function foundationCardY(i: number): number {
    return Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * (i + 3))
}

export function wasteCardX(): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 3)
}
export function wasteCardY(): number {
    return Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * 7)
}

export function tableauPlaceholderX(): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 3)
}
export function tableauPlaceholderY(i: number): number {
    return Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * i)
}
export function foundationPlaceholderX(): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 1.5)
}
export function foundationPlaceholderY(i: number): number {
    return Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * (i + 3))
}
export function wastePlaceholderX(): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 3)
}
export function wastePlaceholderY(): number {
    return Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * 7)
}

export function stockPlaceholderX(): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 1.5)
}
export function stockPlaceholderY(): number {
    return Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * 7)
}

export function undoButtonX(): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 1.5)
}
export function undoButtonY(): number {
    return Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * 2)
}

export function menuButtonX(): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 1.5)
}
export function menuButtonY(): number {
    return Math.floor(appState.cardYOffset)
}

export function newGameButtonX(): number {
    return Math.floor(appState.windowWidth - appState.cardWidth * 1.5)
}
export function newGameButtonY(): number {
    return Math.floor(appState.cardYOffset + (appState.cardHeight + appState.cardSpacing) * 1)
}

export function newGameButtonTextX(): number {
    return appState.cardWidth / 2
}
export function newGameButtonTextY(): number {
    return appState.cardYOffset - 10
}
