import { appState, getCurrentCardState } from './state'
import {
    undoButtonX,
    undoButtonY,
    newGameButtonTextX,
    newGameButtonTextY,
    stockCardX,
    stockCardY,
    menuButtonX,
    menuButtonY,
} from './allXYCoordinates'

export function getSelectedCard(x: number, y: number) {
    // console.log({ x, y })
    // 1. Loop through all cards in the tableau
    const tCard = findCardInTableau(x, y)
    if (tCard) {
        return tCard
    }
    // 2. Loop through all cards in the hand,
    const wCard = findCardInWaste(x, y)
    if (wCard) {
        return wCard
    }
    const sCard = findCardInStock(x, y)
    if (sCard) {
        return sCard
    }
    // 3. Loop through all the cards on foundations
    const fCard = findCardInFoundation(x, y)
    if (fCard) {
        return fCard
    }
}

export function findCardInTableau(x: number, y: number) {
    const currentState = getCurrentCardState()
    for (let i = currentState.tableau.length - 1; i >= 0; i--) {
        for (let j = currentState.tableau[i].length - 1; j >= 0; j--) {
            const card = currentState.tableau[i][j]
            if (
                card &&
                x > card.x &&
                x < appState.cardWidth + card.x &&
                y > card.y &&
                y < card.y + appState.cardHeight
            ) {
                return currentState.tableau[i][j]
            }
        }
    }
}

export function findCardInWaste(x: number, y: number) {
    for (let i = getCurrentCardState().waste.length - 1; i >= 0; i--) {
        const card = getCurrentCardState().waste[i]

        if (
            card &&
            x > card.x &&
            x < appState.cardWidth + card.x &&
            y > card.y &&
            y < card.y + appState.cardHeight
        ) {
            return getCurrentCardState().waste[i]
        }
    }
}

export function findCardInFoundation(x: number, y: number) {
    for (let i = getCurrentCardState().foundation.length - 1; i >= 0; i--) {
        for (let j = getCurrentCardState().foundation[i].length - 1; j >= 0; j--) {
            const card = getCurrentCardState().foundation[i][j]
            if (
                card &&
                x > card.x &&
                x < appState.cardWidth + card.x &&
                y > card.y &&
                y < card.y + appState.cardHeight
            ) {
                return getCurrentCardState().foundation[i][j]
            }
        }
    }
}

function findCardInStock(x: number, y: number) {
    for (let i = getCurrentCardState().stock.length - 1; i >= 0; i--) {
        const card = getCurrentCardState().stock[i]

        if (
            card &&
            x > card.x &&
            x < appState.cardWidth + card.x &&
            y > card.y &&
            y < card.y + appState.cardHeight
        ) {
            return getCurrentCardState().stock[i]
        }
    }
}

export function getStockSelected(x: number, y: number): boolean {
    const stockX = stockCardX()
    const stockY = stockCardY()

    if (x > stockX && x < appState.cardWidth + stockX && y > stockY && y < stockY + appState.cardHeight) {
        return true
    }
    return false
}

export function getUndoButtonSelected(x: number, y: number): boolean {
    const undoX = undoButtonX()
    const undoY = undoButtonY()

    if (x > undoX && x < appState.cardWidth + undoX && y > undoY && y < undoY + appState.cardWidth) {
        return true
    }
    return false
}

export function getSelectedNewGameButtonSelected(x: number, y: number): boolean {
    const buttonX = newGameButtonTextX()
    const buttonY = newGameButtonTextY()

    if (
        x > buttonX - appState.cardWidth / 3 &&
        x < appState.cardHeight * 2 &&
        y > buttonY - appState.cardWidth / 2 &&
        y < buttonY + appState.cardWidth / 5
    ) {
        return true
    }
    return false
}

export function getSelectedFullScreenButton(x: number, y: number): boolean {
    return false
    const plusX = Math.floor(appState.windowWidth - appState.cardWidth + 5)
    const plusY = Math.floor(appState.cardSpacing * 0.5)

    if (
        x > plusX - appState.cardWidth / 2 &&
        x < appState.cardWidth + plusX &&
        y > plusY &&
        y < plusY + appState.cardWidth
    ) {
        return true
    }
    return false
}

export function getMenuButtonSelected(x: number, y: number) {
    const menuX = menuButtonX()
    const menuY = menuButtonY()

    if (x > menuX && x < menuX + appState.cardWidth && y > menuY && y < menuY + appState.cardWidth) {
        return true
    }
    return false
}
