export const themes = {
    HighContrastTheme: {
        backgroundColor: 'black',
        cardNames: 'DefaultCards',
        buttonNames: 'DefaultButtons',
        cardBackColor: '#FFFFFF',
        placeholderColor: '#FFFFFF33',
        highlightColor: '#FFFFFF',
        fillColor: '#FFFFFF',
        textColor: '#FFFFFF',
        textColorShadow: '#FFFFFF33',
    },
    SoftContrastTheme: {
        backgroundColor: '#232323',
        cardNames: 'ModernCards',
        buttonNames: 'DefaultButtons',
        cardBackColor: '#5e5e5e',
        placeholderColor: '#5e5e5e33',
        highlightColor: '#FFFFFF',
        fillColor: '#e2e2e2',
        textColor: '#FFFFFF',
        textColorShadow: '#FFFFFF33',
    },
    HappyPurpleTheme: {
        backgroundColor: '#292d3e',
        cardNames: 'DefaultCards',
        buttonNames: 'DefaultButtons',
        cardBackColor: '#7e57c2',
        placeholderColor: '#7e57c233',
        highlightColor: '#7e57c2',
        fillColor: '#7e57c2',
        textColor: '#7e57c2',
        textColorShadow: '#7e57c233',
    },
    PleasedPinkTheme: {
        backgroundColor: '#FE3562',
        cardNames: 'DefaultCards',
        buttonNames: 'PleasedPinkTheme',
        cardBackColor: '#DFDCEC',
        placeholderColor: '#DFDCECAA',
        highlightColor: '#DFDCEC',
        fillColor: '#DFDCEC',
        textColor: '#DFDCEC',
        textColorShadow: '#DFDCECAA',
    },
    ReversedPleasedPinkTheme: {
        backgroundColor: '#DFDCEC',
        cardNames: 'DefaultCards',
        buttonNames: 'PleasedPinkTheme',
        cardBackColor: '#FE3562',
        placeholderColor: '#FE356233',
        highlightColor: '#FE3562',
        fillColor: '#FE3562',
        textColor: '#FE3562',
        textColorShadow: '#FE356233',
    },
    SoftBlueTheme: {
        // FIXME:
        backgroundColor: '#268bd2',
        cardNames: 'DefaultCards',
        buttonNames: 'SoftBlueTheme',
        cardBackColor: '#DFDCEC',
        placeholderColor: '#DFDCECAA',
        highlightColor: '#DFDCEC',
        fillColor: '#DFDCEC',
        textColor: '#DFDCEC',
        textColorShadow: '#DFDCECAA',
    },
    ReversedSoftBlueTheme: {
        // FIXME:
        backgroundColor: '#DFDCEC',
        cardNames: 'DefaultCards',
        buttonNames: 'SoftBlueTheme',
        cardBackColor: '#268bd2',
        placeholderColor: '#268bd2AA',
        highlightColor: '#268bd2',
        fillColor: '#268bd2',
        textColor: '#268bd2',
        textColorShadow: '#268bd2AA',
    },
}
